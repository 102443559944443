import { setTabSlider } from "../../common/functions";
import { setYmDataLayer } from "../catalog.item/functions";
import initSliderTabs from "../shares/slider_tabs";
import updateProductPrices from "../shares/updateProductPrices";

export function setEventsPopupComplect() {
    const popup = document.querySelector('#popup_complect');

    if(!popup) return;

    popup.addEventListener('click', e => {
        const {target} = e;
        
        if(
            target instanceof HTMLElement &&
            (
                target.classList.contains('popup-complect__close') ||
                target.classList.contains('popup-complect__bg')
            )
        ) {
            e.preventDefault();
            popup.classList.remove('active');
        }

        if(
            target instanceof HTMLElement &&
            target.classList.contains('popup-complect__footer-link')
        ) {
            e.preventDefault();

            const date = new Date(new Date().setMonth(new Date().getMonth() + 3));
           
            window.setCookie('hide_popup_complect', 1, { path:'/', expires: date });
            popup.classList.remove('active');
        }
    })
}

export function initPopupComplect(btn, res) {
    const popup = document.querySelector('#popup_complect');
    const isDetail = btn.closest(`.product-box[data-elementid="${btn.dataset.id}"]`);

    if(
        !popup || 
        !res['also_complect'] ||
        !res['also_complect'].length ||
        isDetail ||
        !!parseInt(window.getCookie('hide_popup_complect'))
    )  return;

    const inner = popup.querySelector('.popup-complect__inner-container');

    inner.innerHTML = res['also_complect'];

    const sliderWrapper = inner.querySelector('.popup-complect__slider');
    const sliderWrapperSwiper = sliderWrapper.querySelector('.catalog-slider__content-inner');
    const itemPricesId = [
        {
            action: 'get-item-prices',
            id: [],
        },
    ];
    const sliderItems = sliderWrapper.querySelectorAll('.item-catalog');
    const swiper = setTabSlider({
        slider: sliderWrapperSwiper,
        count: 2,
        classParent: '.catalog-slider__content-wrapper',
        space: 40
    }).init();

    sliderItems.forEach(item => {
        item.classList.remove('bg-load');
        itemPricesId[0].id.push(item.dataset.elementid)
    });

    updateProductPrices(itemPricesId, 'join_desk', sliderWrapper);
    initSliderTabs(sliderWrapper, sliderItems, swiper);

    popup.classList.add('active');

    setYmDataLayer(sliderItems, 'impressions', 'Попап комплектации - Обязательные комплектующие');
}

window.initPopupComplect = initPopupComplect;