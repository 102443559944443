import { createSpinner } from '../../common/functions'
import { createAvailableContent, senderAddBasket, setYmDataLayer } from './functions'
import { initPopupComplect } from '../popups/popup_complect'

export function eventAddToBasket(e, btn) {
    e.preventDefault()

    if (btn.classList.contains('btn-second')) {
        if (btn.href) {
            e.preventDefault()
            location.href = btn.href
        }

        return
    }

    btn.innerHTML = ''
    btn.append(createSpinner())

    const href = btn.dataset.href ? btn.dataset.href.trim() : btn.href.trim()

    if (!href) return

    const data = $(btn).data()
    data.cnt = 1
    senderAddBasket(`${href}index.php`, data, getBasketSum)

    function getBasketSum(res) {
        if (!res) return

        res = JSON.parse(res)

        const basketHeaderPrice = document.querySelector(
            '.btn-action-cart .btn-action-cart__price'
        )
        const btnItems = document.querySelectorAll(`.btn[data-id="${data.id}"]`)

        if (basketHeaderPrice) {
            basketHeaderPrice.textContent = res.sum
        }

        if (btnItems.length) {
            btnItems.forEach((btn) => {
                btn.classList.add('btn-second')
                btn.textContent = btn.dataset.text
            })
        }

        initPopupComplect(btn, res)
        setYmDataLayer(btn.closest('.item-catalog'), 'add')
        senderAddBasket('/local/ajax/basket.php', undefined, getBasketCount)
    }

    function getBasketCount(res) {
        if (!res) return

        const basketHeaderQty = document.querySelector(
            '.btn-action-cart .btn-action-cart__qty'
        )

        if (basketHeaderQty) {
            basketHeaderQty.textContent = res
            basketHeaderQty.classList.add('active')
        }

        if ($(btn).parents('.cat-hidden-size').length) {
            $(btn)
                .siblings()
                .hide(function () {
                    $(btn).css('width', '100%')
                })
        }

        if(window.index_1) {
            const totalSum = document.querySelector('.btn-action-cart__price');
            const wrapperForHandler = document.querySelector('.page__content');

            if(totalSum && wrapperForHandler) {
                const basket_1 = new index_1.default(wrapperForHandler, totalSum);
                basket_1.update();
            }
        }
    }
}

export function eventClickItemLink(e, link) {
    e.preventDefault()

    const item = link.closest('.item-catalog')

    setYmDataLayer(item, 'click')
    window.open(link.href, e.ctrlKey ? '_blank' : '_self');
}

export function eventHoverItem(item) {
    if(!item.hasAttribute('data-ajax-hover')) return

    item.removeAttribute('data-ajax-hover');

    const catAvailable = item.querySelector('.cat-available__container');
    const ajax = $.ajax({
        type: 'POST',
        url: '/local/ajax/getelementstock.php',
        data: `action=getElementStockById&count=1&unit=шт&id=${item.dataset.elementid}`
    })

    ajax.done(function(result) {
        result = JSON.parse(result);

        catAvailable.append(createAvailableContent(result));
        $(catAvailable).slideDown();
    })
}
