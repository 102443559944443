import { setYmDataLayerPromo } from "../../../../../../../js/common/functions";
import { setTabSlider } from "../../../../../js/common/functions";
import { setYmDataLayer } from "../../../../../js/includes/catalog.item/functions";

let debounce;

window.addEventListener('load', function () {
    let ajax = $.ajax({
        url: '/local/ajax/getBannerForLeftMenu.php',
        type: 'POST'
    });
    

    ajax.done(function(res) {
        const resposive = JSON.parse(res);

        setBanners(resposive);

        const catalogFixed = document.querySelector('.catalog-fixed-wrap');
        const btnCatalogFixed = document.querySelector('.btn-action-catalog');
        let catalogListItems;

        let header = document.querySelector('header');
        let headerParent;
        let watch;

        let id;
        let timeoutSubmenuOpen;
        let timeoutSubmenuClose = [];

        // for fixed catalogMenu
        if (catalogFixed && btnCatalogFixed) {
            catalogListItems = catalogFixed.querySelectorAll('.catalog-list>ul>li');

            catalogFixed.addEventListener('click', (e) => {
                if (
                    e.target.classList.contains('catalog-mask') ||
                    e.target.classList.contains('inner-wrap')
                ) {
                    catalogFixed.classList.remove('active');
                    btnCatalogFixed.classList.remove('active');
                }
            });

            btnCatalogFixed.addEventListener('click', (e) => {
                e.preventDefault();

                if (!btnCatalogFixed.classList.contains('active')) {
                    const hasChildItem = catalogFixed.querySelector(
                        '.catalog-list>ul>li>a.has-child'
                    );
                    headerParent = header.closest('.wrap');

                    if (hasChildItem) {
                        showSubmenu(hasChildItem.parentElement);
                    }

                    if (
                        window.pageYoffset === 0 &&
                        headerParent.classList.contains('header-catalog')
                    ) {
                        catalogFixed.style.paddingTop = header.offsetHeight + 'px';
                        catalogFixed.classList.add('active');
                        btnCatalogFixed.classList.add('active');
                    } else {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });

                        watch = setInterval(() => {
                            headerParent = header.closest('.wrap');

                            if (!headerParent.classList.contains('header-catalog'))
                                return;

                            catalogFixed.style.paddingTop =
                                header.offsetHeight + 'px';
                            catalogFixed.classList.add('active');
                            btnCatalogFixed.classList.add('active');

                            clearInterval(watch);
                        }, 300);
                    }
                } else {
                    catalogFixed.classList.remove('active');
                    btnCatalogFixed.classList.remove('active');
                    catalogListItems.forEach((li) => li.classList.remove('active'));
                }
            });

            catalogListItems.forEach((li) => {
                li.addEventListener('mouseover', () => {
                    clearTimeout(timeoutSubmenuOpen);

                    timeoutSubmenuOpen = setTimeout(() => {
                        showSubmenu(li, catalogListItems);
                    }, 100);
                });
            });
        } else {
            // catalogMenu on main page
            catalogListItems = document.querySelectorAll(
                '.page__inner-main .main-catalog-box .catalog-list>ul>li'
            );

            if (catalogListItems.length) {
                catalogListItems.forEach((li) => {
                    li.addEventListener('mouseover', () => {
                        id = getIdElement(catalogListItems, li);
            
                        clearTimeout(timeoutSubmenuClose[id]);
            
                        timeoutSubmenuOpen = setTimeout(function () {
                            showSubmenu(li);
                        }, 200);
                    });

                    li.addEventListener('mouseout', () => {
                        clearTimeout(timeoutSubmenuOpen);

                        id = getIdElement(catalogListItems, li);

                        timeoutSubmenuClose[id] = setTimeout(function () {
                            li.classList.remove('active')
                        }, 300);
                    });
                });
            }
        }
    })
});

function setBanners(res) {
    if(
        !res.success || 
        !res.banners
    ) return;

    const bannerWraps = document.querySelectorAll('.catalog-list [data-section-id]');

    if(bannerWraps.length) {
        bannerWraps.forEach(wrap => {
            if(
                Object.keys(res.banners).includes(wrap.dataset.sectionId) &&
                res.banners[wrap.dataset.sectionId].length
            ) {
                let arrLenght = res.banners[wrap.dataset.sectionId].length;
                let link = wrap.querySelector('.last-column__banner');
                let randomIndex;
                let obj;
                let img;

                if(arrLenght > 1) {
                    randomIndex = getRandomInt(arrLenght);
                } else {
                    randomIndex = 0;
                };

                obj = res.banners[wrap.dataset.sectionId][randomIndex];
                img = createBannerImg(obj);
                
                link.href = obj.link;
                link.title = obj.title;
                link.append(img);
                link.setAttribute('data-erid', obj.erid);
                link.setAttribute('data-mark', obj.mark);
                link.setAttribute('data-promo-id', obj.id);
                link.setAttribute('data-promo-name', obj.name);
                link.addEventListener('click', e => {
                    e.preventDefault();

                    clearTimeout(debounce);
                    debounce = setTimeout(() => {
                        const arr = [{
                            id: link.dataset.promoId,          
                            name: link.dataset.promoName,
                            creative: "Баннер в меню каталога",
                            position: 1
                        }];
    
                        setYmDataLayerPromo(arr, 'promoClick');
                        location.href = obj.link;
                    }, 300)
                })
                createTooltip(link);
            } else {
                wrap.innerHTML = '';
            }
        })
    }
}

function createTooltip(element) {
    if (element.classList.contains('ad-tooltip--init')) return;
    const erid = element.getAttribute('data-erid');
    let eridText = '';
    const mark = element.getAttribute('data-mark');
    if (erid === undefined || erid === null || erid === '' || mark === undefined || mark === '1') return;
    if (erid !== undefined && erid !== null && erid !== '') {
        eridText = `, erid:${erid}`;
    }
    const tooltipHtml = `
    <div class="ad-tooltip">
        <div class="ad-tooltip__header">
            <span class="ad-tooltip__header-title">Реклама</span>
            <img src="/local/templates/main/img/icon-ad.svg" alt="" class="ad-tooltip__header-icon">
        </div>
        <div class="ad-tooltip__body">
            <span class="ad-tooltip__body-text">Рекламодатель ООО "Делай мебель", ОГРН: 1157746754956${eridText}</span>
        </div>
    </div>
    `;
    element.insertAdjacentHTML('beforeend', tooltipHtml);
    setTooltipTimeout(element.querySelector('.ad-tooltip'));
    element.classList.add('ad-tooltip--init');
}

let timeoutTooltip;

function setTooltipTimeout(tooltipElement) {
    tooltipElement.addEventListener('mouseenter', function() {
        clearTimeoutTooltip()
        tooltipElement.querySelector('.ad-tooltip .ad-tooltip__body').classList.add('ad-tooltip__body--active');
    })
    tooltipElement.addEventListener('mouseleave', function() {
        timeoutTooltip = setTimeout(() => {
            tooltipElement.querySelector('.ad-tooltip__body').classList.remove('ad-tooltip__body--active');
        }, 300)
    })
}

function clearTimeoutTooltip() {
    clearTimeout(timeoutTooltip);
}

function showSubmenu(li, catalogListItems = false) {
    let imgWrap = li.querySelector('[data-section-id]');

    if(imgWrap && !imgWrap.classList.contains('init')) {
        imgWrap.classList.add('init');


        
        let img = imgWrap.querySelector('img');

        if(img) {
            img.src = img.dataset.srcImage;
        }

        const link = imgWrap.querySelector('[data-erid]');
        const arr = [{
            id: link.dataset.promoId,          
            name: link.dataset.promoName,
            creative: "Баннер в меню каталога",
            position: 1
        }];

        setYmDataLayerPromo(arr, 'promoView');
    }

    if(catalogListItems) {
        catalogListItems.forEach(li => li.classList.remove('active'));
    }

    li.classList.add('active');
    loadSlider(li);
}

function getIdElement(arr, element) {
    let id;

    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === element) {
            id = i;
            break;
        }
    }

    return id;
}

function createBannerImg(res) {
    const img = document.createElement('img');

    img.dataset.srcImage = res.banner_url;
    img.alt = res.title;

    return img;
}

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

function loadSlider(li, mobile = false) {
    if(li.classList.contains('sliderInit')) return;

    const wrapper = li.querySelector('.catalog-slider__content');

    if(!wrapper) return;

    li.classList.add('sliderInit');

    let ajax = $.ajax({
        url: wrapper.dataset.ajaxUrl,
        type: 'POST'
    });

    ajax.done(function(response) {
        async function fn() {
            const itemPricesId = [
                {
                    action: 'get-item-prices',
                    id: [],
                },
            ];

            wrapper.innerHTML = response;

            const items = wrapper.querySelectorAll('[data-elementid]');

            if (items.length) {
                items.forEach(item => itemPricesId[0].id.push(item.dataset.elementid));
                setYmDataLayer(items, 'impressions', 'Слайдер в меню каталога');
                updateProductPrices(itemPricesId, mobile ? 'join_mobile' : 'join_desk', wrapper);
            } else {
                wrapper.remove();
            }
        }

        fn().then(function () {
            const catalogSlider = wrapper.querySelector(
                '.catalog-slider__content-inner'
            );
            
            if (!catalogSlider) return;

            setTabSlider({
                slider: catalogSlider,
                classParent: '.catalog-slider__content-wrapper',
                classPrev: '.catalog-slider__arrow-prev',
                classNext: '.catalog-slider__arrow-next',
                space: 35,
            }).init();
        });
    });
}